import ActionsType from "./ActionsType";
import ErrorTypes from "../../tools/ErrorTypes";
import mainAxios from "../../Axios/Axios";
import store from "../createStore";
import { format } from "date-fns";
import xml2js from "xml2js";
import { importStyles, importFooter } from "./importActions";
import {
	changeForm,
	setPrice,
	addMisc,
	addPrice,
	addInventory,
} from "./formActions";
import { setSummery, setPromotionDiscount } from "./summeryActions";
import { alert } from "./UIActions";
import creditCardType, {
	getTypeInfo,
	types as CardType,
} from "credit-card-type";
import generator from "generate-password";

const generatePassword = () => {
	const pwd = generator.generate({
		length: 10,
		lowercase: true,
		uppercase: true,
		numbers: true,
		symbols: true,
	});
	return pwd;
};

const fetchClient = (clientid) => {
	return async (dispatch) => {
		try {
			console.log(process.env.REACT_APP_RESERVATION_API_BASE_URL);
			const clientReq = await mainAxios.get(
				`${process.env.REACT_APP_RESERVATION_API_BASE_URL}api/rentworks/initdata?clientid=${clientid}`
			);

			let payload = {};

			clientReq.data.allSettings.forEach((item) => {
				payload[item.keyValue] = item.value;
			});

			payload["locations"] = clientReq.data.locations;
			payload["storeHours"] = clientReq.data.storeHours;
			payload["vehicleTypes"] = clientReq.data.vehicleTypes;
			payload["ClientId"] = clientid;

			payload.DateFormat = payload.DateFormat.replaceAll("-", "/")
				.replace("DD", "dd")
				.replace("YYYY", "yyyy");

			const defaultConfig = {
				navbar: {
					custom: true,
					steppers: [
						{ title: "Select Vehicle", type: "Selected Vehicle", icon: "car" },
						{
							title: "Vehicle Details",
							type: "Vehicle Details",
							icon: "detail",
						},
						{ title: "Extras", type: "Add Ons", icon: "plus-square" },
						{ title: "Insurance", type: "Insurance", icon: "info" },
						{ title: "Info", type: "User info", icon: "user-info" },
						{ title: "Checkout", type: "Checkout", icon: "credit-card" },
					],
				},
			};

			payload.Config = payload.Config
				? JSON.parse(payload.Config)
				: defaultConfig;
			payload.ShowPickupDropoffAddress = payload.ShowPickupDropoffAddress
				? JSON.parse(payload.ShowPickupDropoffAddress)
				: null;

			dispatch({ type: ActionsType.FETCH_CLIENT, data: payload });

			dispatch(importStyles(payload.Theme));
			dispatch(importFooter(payload["Footer"]));

			if (payload.locations.length === 1) {
				dispatch(changeForm("location", payload.locations[0].locationId));
			}
		} catch (err) {
			console.log(err);
			alert("Error Occured while feptching the user data.");
		}
	};
};

const fetchStoreHours = (locationId) => {
	return async (dispatch) => {
		try {
			const state = store.getState();

			let url = `/api/rentworks/${state.UISettings.clientId}/GetStoreHours?locationCode=${locationId}`;

			const response = await mainAxios.get(url);

			dispatch({
				type: ActionsType.SET_STORE_HOURS,
				data: response.data,
			});
		} catch (err) {
			console.log(err);
			alert("Error Occured while fetching Store Hours");
		}
	};
};

const fetchVehicleType = (pickDate, dropOffDate) => {
	return async (dispatch) => {
		try {
			const state = store.getState();
			let pickUpDate = pickDate || state.form.pickUpDate;
			let returnDate = dropOffDate || state.form.returnDate;
			let rateQualifier = state.form.CorpDiscoundNumber;
			let {
				returnTime,
				pickUpTime,
				location,
				differentDropOff,
				differentDropOffLocation,
			} = state.form;

			const checkOut =
				format(pickUpDate, "yyyy-MM-dd").toString() +
				"T" +
				format(pickUpTime, "HH:mm").toString();
			const checkIn =
				format(returnDate, "yyyy-MM-dd").toString() +
				"T" +
				format(returnTime, "HH:mm").toString();

			var prefs = state.UISettings.vehicleTypes.map((item) => ({
				Size: item.id,
			}));

			const payload = {
				VehAvailRQCore: {
					Status: "Available",
					VehRentalCore: {
						PickUpDateTime: checkOut,
						ReturnDateTime: checkIn,
						PickUpLocation: {
							LocationCode: location,
						},
						ReturnLocation: {
							LocationCode: differentDropOff
								? differentDropOffLocation
								: location,
						},
					},
					RateQualifier: {
						CorpDiscountNmbr: rateQualifier,
					},
					VehPrefs: {
						VehPref: prefs,
					},
				},
			};

			let url = `/api/Rentworks/${state.UISettings.clientId}/GetVehAvailRate`;

			const vehicleReq = await mainAxios.post(url, payload);
			console.log();
			if (vehicleReq.data.vehicleTypes.length === 0) {
				dispatch({
					type: ActionsType.SET_VEHICLES,
					data: { vehicles: vehicleReq.data },
				});
				throw ErrorTypes.NO_VEHICLE_AVAILABLE;
			} else {
				dispatch({
					type: ActionsType.SET_VEHICLES,
					data: { vehicles: vehicleReq.data },
				});
			}
		} catch (err) {
			if (err === ErrorTypes.NO_VEHICLE_AVAILABLE) {
				throw ErrorTypes.NO_VEHICLE_AVAILABLE;
			}
			console.log(err);
		}
	};
};

const fetchVehicles = () => {
	return async (dispatch) => {
		try {
			const state = store.getState();

			let pickUpDate = state.form.pickUpDate;
			let returnDate = state.form.returnDate;
			let vehicleTypeId = state.form.selectedVehicle.VehicleTypeId;

			let { returnTime, pickUpTime, location } = state.form;

			const checkOut =
				format(pickUpDate, "yyyy-MM-dd").toString() +
				"T" +
				format(pickUpTime, "HH:mm").toString();
			const checkIn =
				format(returnDate, "yyyy-MM-dd").toString() +
				"T" +
				format(returnTime, "HH:mm").toString();

			let rateQualifier = state.form.CorpDiscoundNumber;

			const payload = {
				VehAvailRQCore: {
					Status: "Available",
					VehRentalCore: {
						PickUpDateTime: checkOut,
						ReturnDateTime: checkIn,
						PickUpLocation: {
							LocationCode: state.form.location,
						},
						ReturnLocation: {
							LocationCode: state.form.location,
						},
					},
					RateQualifier: {
						CorpDiscountNmbr: rateQualifier,
					},
					VehPrefs: {
						VehPref: {
							Size: vehicleTypeId,
						},
					},
				},
			};

			let url = `/api/Rentworks/${state.UISettings.clientId}/GetVehAvailRate`;

			const vehicleReq = await mainAxios.post(url, payload);

			if (vehicleReq.data.length === 0) {
				throw ErrorTypes.NO_VEHICLE_ITEM_AVAILABLE;
			} else {
				dispatch({
					type: ActionsType.SET_VEHICLE_ITEMS,
					data: { vehicles: vehicleReq.data },
				});
			}
		} catch (err) {
			if (err === ErrorTypes.NO_VEHICLE_AVAILABLE) {
				throw ErrorTypes.NO_VEHICLE_AVAILABLE;
			}
			console.log(err);
		}
	};
};

const fetchInventoryCharges = (VehicleTypeId) => {
	return async (dispatch) => {
		try {
			const state = store.getState();

			VehicleTypeId = VehicleTypeId || state.form.selectedVehicle.VehicleTypeId;

			const lang = new URLSearchParams(window.location.search).get("lang");

			let url = `/api/v3/Inventory/Groups/?ClientId=${
				state.UISettings.clientId
			}&IsOnline=${true}&locationid=${
				state.form.location
			}&VehicleTypeId=${VehicleTypeId}`;

			if (lang) {
				url += "&Language=" + lang;
			}

			const inventoryChargesReq = await mainAxios.get(url);

			const inventoryCharges = inventoryChargesReq.data;

			const selectedInventory = state.form.selectedInventory || [];

			if (inventoryChargesReq.length === 0)
				throw ErrorTypes.NO_INVENTORY_AVAILABLE;

			inventoryCharges.forEach((item) => {
				const isAlreadySelected = !!selectedInventory.find(
					(inventory) => inventory.Id === item.Id
				);

				if (!isAlreadySelected) {
					if (!item.IsOptional) {
						if (item.IsQuantity) {
							item = { ...item, Quantity: 1 };
						}
						dispatch(addInventory(item));
					}
				} else {
					dispatch(addPrice(item.total));
				}
			});

			dispatch({ type: ActionsType.SET_INVENTORY, data: { inventoryCharges } });
		} catch (err) {
			if (err === ErrorTypes.NO_INVENTORY_AVAILABLE) {
				throw ErrorTypes.NO_INVENTORY_AVAILABLE;
			}
			console.log(err);
		}
	};
};

const ageCalculator = (dob) => {
	var month_diff = Date.now() - dob.getTime();
	var age_dt = new Date(month_diff);
	var year = age_dt.getUTCFullYear();
	var age = Math.abs(year - 1970);
	return age;
};

const fetchMiscCharges = (VehicleTypeId) => {
	return async (dispatch) => {
		try {
			const state = store.getState();
			VehicleTypeId = VehicleTypeId || state.form.selectedVehicle.vehicleTypeId;
			let pickUpDate = state.form.pickUpDate;
			let returnDate = state.form.returnDate;
			let vendorRateID = "";
			if (state.form.selectedVehicle.vendorRateID) {
				vendorRateID = state.form.selectedVehicle.vendorRateID.toString();
			}

			let {
				returnTime,
				pickUpTime,
				location,
				differentDropOff,
				differentDropOffLocation,
			} = state.form;

			const checkOut =
				format(pickUpDate, "yyyy-MM-dd").toString() +
				"T" +
				format(pickUpTime, "HH:mm").toString();
			const checkIn =
				format(returnDate, "yyyy-MM-dd").toString() +
				"T" +
				format(returnTime, "HH:mm").toString();
			let rateQualifier = state.form.CorpDiscoundNumber;
			const payload = {
				VehAvailRQCore: {
					Status: "Available",
					VehRentalCore: {
						PickUpDateTime: checkOut,
						ReturnDateTime: checkIn,
						PickUpLocation: {
							LocationCode: location,
						},
						ReturnLocation: {
							LocationCode: differentDropOff
								? differentDropOffLocation
								: location,
						},
					},
					RateQualifier: {
						CorpDiscountNmbr: rateQualifier,
						vendorRateID: vendorRateID,
					},
					VehPrefs: {
						VehPref: [
							{
								Size: VehicleTypeId,
							},
						],
					},
				},
			};

			if (state.form.dateOfBirth) {
				payload.VehAvailRQCore.DriverType = {
					BirthDate: format(state.form.dateOfBirth, "MM/dd/yyyy").toString(),
					Age: ageCalculator(state.form.dateOfBirth),
				};
			}

			let url = `/api/Rentworks/${state.UISettings.clientId}/GetMiscCharges`;

			const miscChargesReq = await mainAxios.post(url, payload);

			const miscCharges = miscChargesReq.data[0];

			const selectedMisc = state.form.selectedMisc || [];

			if (miscCharges.length === 0) throw ErrorTypes.NO_MISC_AVAILABLE;

			miscCharges.forEach((item) => {
				const isAlreadySelected = !!selectedMisc.find(
					(misc) => misc.id === item.id
				);

				if (!isAlreadySelected) {
					if (!item.isOptional) {
						dispatch(addMisc(item));
					}
				} else {
					dispatch(addPrice(item.total));
				}
			});

			dispatch({ type: ActionsType.SET_MISC, data: { miscCharges } });

			dispatch(getSummery());
		} catch (err) {
			if (err === ErrorTypes.NO_MISC_AVAILABLE) {
				throw ErrorTypes.NO_MISC_AVAILABLE;
			}
			console.log(err);
		}
	};
};

const getCustomer = () => {
	return async (dispatch) => {
		try {
			const state = store.getState();

			const payload = {
				clientId: state.UISettings.clientId,
				username: state.form.email,
				password: state.form.password,
			};

			const customer = await mainAxios.post(`api/v3/customers/login`, payload);

			if (customer) {
				dispatch(changeForm("customerId", customer.data.customerId));
				dispatch(changeForm("firstName", customer.data.firstName));
				dispatch(changeForm("lastName", customer.data.lastName));
				dispatch(changeForm("email", customer.data.email));
				dispatch(changeForm("phoneNumber", customer.data.hPhone));
				dispatch(
					changeForm(
						"dateOfBirth",
						customer.data.dateOfbirth ? new Date(customer.data.dateOfbirth) : ""
					)
				);
				dispatch(changeForm("driverLicNumber", customer.data.licenseNumber));
				dispatch(
					changeForm(
						"driverLicExp",
						customer.data.licenseExpiryDate
							? new Date(customer.data.licenseExpiryDate)
							: ""
					)
				);
				dispatch(
					changeForm(
						"driverLicIssueDate",
						customer.data.licenseIssueDate
							? new Date(customer.data.licenseIssueDate)
							: ""
					)
				);
				dispatch(changeForm("address", customer.data.address1));
				dispatch(changeForm("city", customer.data.city));
				dispatch(changeForm("zipCode", customer.data.zipCode));
				dispatch(changeForm("state", customer.data.stateId));
				dispatch(changeForm("country", customer.data.countryId));

				return true;
			}

			return false;
		} catch (err) {
			return false;
		}
	};
};

const fetchTaxes = () => {
	return async (dispatch) => {
		try {
			const state = store.getState();
			let vehicleTypeId = state.form.selectedVehicle.vehicleTypeId;

			let pickUpDate = state.form.pickUpDate;
			let returnDate = state.form.returnDate;

			let returnTime = state.form.returnTime;
			let pickUpTime = state.form.pickUpTime;

			const checkOut =
				format(pickUpDate, "yyyy-MM-dd").toString() +
				"T" +
				format(pickUpTime, "HH:mm").toString();
			const checkIn =
				format(returnDate, "yyyy-MM-dd").toString() +
				"T" +
				format(returnTime, "HH:mm").toString();

			let rateQualifier = state.form.CorpDiscoundNumber;

			const payload = {
				VehAvailRQCore: {
					RateQualifier: {
						CorpDiscountNmbr: rateQualifier,
						VendorRateID: state.form.selectedVehicle.vendorRateID.toString(),
					},
					Status: "Available",
					VehRentalCore: {
						PickUpDateTime: checkOut,
						ReturnDateTime: checkIn,
						PickUpLocation: {
							LocationCode: state.form.location,
						},

						ReturnLocation: {
							LocationCode: state.form.differentDropOff
								? state.form.differentDropOffLocation
								: state.form.location,
						},
					},
					VehPrefs: {
						VehPref: [
							{
								Size: vehicleTypeId,
							},
						],
					},
				},
			};

			if (state.form.dateOfBirth) {
				payload.VehAvailRQCore.DriverType = {
					BirthDate: format(state.form.dateOfBirth, "MM/dd/yyyy").toString(),
					Age: ageCalculator(state.form.dateOfBirth),
				};
			}

			let url = `/api/Rentworks/${state.UISettings.clientId}/GetTaxes`;

			const taxReq = await mainAxios.post(url, payload);

			dispatch({ type: ActionsType.SET_TAXES, data: taxReq.data });
			dispatch(getSummery());
		} catch (err) {
			console.log(err);
			alert("Error Occured while fetching the Taxes");
		}
	};
};

const getSummery = () => {
	return async (dispatch) => {
		return new Promise(async (resolve, reject) => {
			try {
				const state = store.getState();
				const totalPrice = state.price.reduce((total, val) => total + val, 0);

				let pickUpDate = state.form.pickUpDate;
				let returnDate = state.form.returnDate;

				let returnTime = state.form.returnTime;
				let pickUpTime = state.form.pickUpTime;

				const checkOut =
					format(pickUpDate, "yyyy-MM-dd").toString() +
					"T" +
					format(pickUpTime, "HH:mm").toString();
				const checkIn =
					format(returnDate, "yyyy-MM-dd").toString() +
					"T" +
					format(returnTime, "HH:mm").toString();

				const locationId = state.form.location;

				let ReturnLocation = state.form.differentDropOff
					? state.form.differentDropOffLocation
					: state.form.location;
				let promoDesc = state.form.promoCode;
				let vehClassSize = state.form.selectedVehicle.vehicleTypeId;
				let promotionData = {};
				const promotionModelParameter = {
					ClientId: parseInt(state.UISettings.clientId),
					Status: "statusValue",
					PickUpDateTime: checkOut,
					ReturnDateTime: checkIn,
					PickUpLocation: locationId,
					ReturnLocation: ReturnLocation,
					vendorRateID: state.form.selectedVehicle.vendorRateID.toString(),
					PromoDesc: promoDesc,
					VehClassSize: vehClassSize,
					CorpDiscountNmbr: state.form.CorpDiscoundNumber,
				};
				if (promoDesc) {
					await GetPromotionData(promotionModelParameter)
						.then((promotionDiscount) => {
							promotionData = promotionDiscount;
							console.log(promotionDiscount);
						})
						.catch((err) => {
							console.error(err);
						});
				}

				let summary = {};

				if (Object.keys(promotionData).length > 0) {
					if (promotionData.Error) {
						summary.total = state.form.selectedVehicle.fullValue;
						dispatch(setPromotionDiscount(promotionData));
					} else {
						dispatch(setPromotionDiscount(promotionData));
						summary.total = promotionData.RateTotalAmountWithPromo;
					}
				} else {
					dispatch(setPromotionDiscount(promotionData));
					summary.total = state.form.selectedVehicle.fullValue;
				}
				summary.payNowDiscountTotal = 0;

				if (state.miscCharges) {
					state.miscCharges.forEach((item) => {
						if (item.code === "PayNow") {
							summary.payNowDiscountTotal =
								Math.round((summary.total * 100 * item.value) / 100) / 100;
						}
					});
				}

				summary["miscCharges"] = state.form.selectedMisc;

				summary["miscCharges"].forEach((item) => {
					console.log(item);

					if (item.code !== "PayNow") {
						if (item.isQuantity && item.calculationType == "FixedType") {
							item.fullTotal =
								item.value * (item.Quantity ? parseInt(item.Quantity) : 1);
							summary.total =
								(Math.round(summary.total * 100) +
									Math.round(item.fullTotal * 100)) /
								100;
						} else if (item.calculationType == "Percentage") {
							item.fullTotal = item.total;
							summary.total =
								(Math.round(summary.total * 100) +
									Math.round(item.fullTotal * 100)) /
								100;
						} else if (item.calculationType == "PerDay") {
							item.fullTotal =
								item.total * (item.Quantity ? parseInt(item.Quantity) : 1);
							summary.total =
								(Math.round(summary.total * 100) +
									Math.round(item.fullTotal * 100)) /
								100;
						}
					}
				});

				summary.baseRate = state.form.selectedVehicle.fullValue;
				summary.avgPerDayRate = state.form.selectedVehicle.price;
				summary.totalDays = Math.round(
					(new Date(checkIn) - new Date(checkOut)) / (1000 * 60 * 60 * 24)
				);
				summary["rateSummaryItems"] = [
					{
						endDate: checkIn,
						kMorMileageAllowed: state.form.selectedVehicle.rates.rateDistance[0]
							.unlimited
							? 1
							: 0,
						rate: state.form.selectedVehicle.price,
						startDate: checkOut,
						type: "Daily",
						units: 1,
					},
				];
				summary.promotionDiscount =
					promotionData.Value > 0 ? promotionData.Value : 0;

				summary.totalTax = 0;
				let subtotal = 0;
				let clonedArray = JSON.parse(JSON.stringify(state.taxes));
				if (promotionData.Error) {
					subtotal = summary.baseRate;
				} else {
					subtotal =
						promotionData.RateTotalAmount != null || undefined
							? promotionData.RateTotalAmount - promotionData.Value
							: summary.baseRate;
				}
				summary["taxes"] = [
					...clonedArray.reduce((result, item) => {
						if (!item.isOptional || item.isDefaultSelectedForReservation) {
							let amount = 0;
							if (item.percentage > 0) {
								amount = (subtotal * 100 * item.percentage) / 10000;
								item.value = amount;
							} else {
								amount = item.value;
							}

							summary.totalTax = (summary.totalTax * 100 + amount * 100) / 100;
						}
						result.push(item);
						return result;
					}, []),
				];

				if (state.fees) {
					let clonedFeesArray = JSON.parse(JSON.stringify(state.fees));

					summary["fees"] = [
						...clonedFeesArray.reduce((result, item) => {
							if (
								!item.isOptional ||
								item.isDefaultSelectedForReservation ||
								(state.UISettings.hasAdditionalDriver && item.code === "AD")
							) {
								result.push(item);
								let amount = 0;
								if (item.percentage > 0) {
									amount = (summary.baseRate * 100 * item.percentage) / 10000;
								} else {
									amount = item.value;
								}

								summary.totalTax =
									(summary.totalTax * 100 + amount * 100) / 100;
							}

							return result;
						}, []),
					];
				} else {
					summary["fees"] = [];
				}

				summary.taxes = [
					...summary.taxes,
					...summary["miscCharges"]
						.filter((m) => m.taxes)
						.reduce((result, item) => {
							item.taxes.forEach((t) => {
								if (t.code !== "PayNow") {
									result.push({
										...t,
										value:
											t.value * (item.Quantity ? parseInt(item.Quantity) : 1),
									});
									summary.totalTax =
										(summary.totalTax * 100 +
											t.value *
												(item.Quantity ? parseInt(item.Quantity) : 1) *
												100) /
										100;
								}
							});

							return result;
						}, []),
					...summary["fees"]
						.filter((m) => m.taxes)
						.reduce((result, item) => {
							item.taxes.forEach((t) => {
								if (t.code !== "PayNow") {
									result.push({
										...t,
										value:
											t.value * (item.Quantity ? parseInt(item.Quantity) : 1),
									});
									summary.totalTax =
										(summary.totalTax * 100 +
											t.value *
												(item.Quantity ? parseInt(item.Quantity) : 1) *
												100) /
										100;
								}
							});

							return result;
						}, []),
				];

				summary.miscCharges
					.filter((m) => m.taxes)
					.forEach((item) => {
						item.taxes.forEach((t) => {
							if (t.code === "PayNow") {
								summary.payNowDiscountTotal =
									(summary.payNowDiscountTotal * 100 +
										t.value *
											(item.Quantity ? parseInt(item.Quantity) : 1) *
											100) /
									100;
							}
						});
					});

				summary.fees
					.filter((m) => m.taxes)
					.forEach((item) => {
						item.taxes.forEach((t) => {
							if (t.code === "PayNow") {
								summary.payNowDiscountTotal =
									(summary.payNowDiscountTotal * 100 +
										t.value *
											(item.Quantity ? parseInt(item.Quantity) : 1) *
											100) /
									100;
							}
						});
					});

				let ts = summary["taxes"].reduce((final, item) => {
					if (final.filter((t) => t.name === item.name).length > 0) {
						final.filter((t) => t.name === item.name)[0].value += item.value;
					} else {
						final.push(item);
					}

					return final;
				}, []);

				summary["taxes"] = ts;

				summary.totalTax = Math.round(summary.totalTax * 100) / 100;

				summary.total =
					(Math.round(summary.total * 100) +
						Math.round(summary.totalTax * 100)) /
					100;

				summary.payNowTotal =
					(summary.total * 100 + summary.payNowDiscountTotal * 100) / 100;

				summary.advancePayment =
					state.UISettings.paymentPercentage &&
					state.UISettings.paymentPercentage < 100
						? (summary.total * state.UISettings.paymentPercentage) / 100
						: 0;

				if (promotionData.RateTotalAmountWithPromo) {
					promotionData.totalTaxes = summary.totalTax;
					setPromotionDiscount(promotionData);
				}

				dispatch(setPrice(summary.total - totalPrice));
				dispatch(changeForm("miscCharges", summary.miscCharges));
				dispatch(setSummery(summary));

				resolve();
			} catch (err) {
				console.log(err);
				reject(err);
			}
		});
	};
};

const getQuote = () => {
	return async (dispatch) => {
		try {
			const state = store.getState();

			const payload = {
				ClientId: parseInt(state.UISettings.clientId),
				StartDate:
					format(state.form.pickUpDate, "yyyy-MM-dd").toString() +
					"T" +
					format(state.form.pickUpTime, "HH:mm").toString(),
				EndDate:
					format(state.form.returnDate, "yyyy-MM-dd").toString() +
					"T" +
					format(state.form.returnTime, "HH:mm").toString(),
				VehicleTypeId: parseInt(state.form.selectedVehicle.VehicleTypeId),
				VehicleId: null,
				CheckoutLocationId: parseInt(state.form.location),
				CheckinLocationId: parseInt(
					state.form.differentDropOff
						? state.form.differentDropOffLocation
						: state.form.location
				),
				MiscCharges: state.form.selectedMisc,
				InventoryCharges: state.form.selectedInventory,
				PromotionIds: state.form.promoId || [],
				FirstName: state.form.firstName,
				LastName: state.form.lastName,
				customerPassword: generatePassword(),
				Company: null,
				Phone: state.form.phoneNumber,
				PreAdjustment: state.summery.dropOffCharges,
				email: state.form.email,
				Address: state.form.address,
				City: state.form.city,
				ZipCode: state.form.zipCode,
				StateId: parseInt(state.form.state),
				CountryId: parseInt(state.form.country),
				TaxIds: [
					...state.taxes.reduce((result, item) => {
						if (!item.isOptional || item.isDefaultSelectedForReservation) {
							result.push(item.id);
						}
						return result;
					}, []),
				],
				CreatedDate:
					format(new Date(), "yyyy-MM-dd").toString() +
					"T" +
					format(new Date(), "HH:mm").toString(),
			};

			if (state.UISettings.driverLicense === "1") {
				payload["LicenseExpiryDate"] = format(
					state.form.driverLicExp,
					"yyyy-MM-dd"
				).toString();
				payload["LicenseNumber"] = state.form.driverLicNumber;
			}
			if (state.UISettings.showDOB === true) {
				payload["DateOfBirth"] = format(
					state.form.dateOfBirth,
					"yyyy-MM-dd"
				).toString();
			}

			const submitReq = await mainAxios.post("/api/v3/Quotes", payload);
			return submitReq.data;
		} catch (err) {
			console.log(err);
			alert("Error Occured while booking.");

			return false;
		}
	};
};

const getPaymentLink = () => {
	return async (dispatch) => {
		try {
			const state = store.getState();

			const payload = {
				clientId: parseInt(state.UISettings.clientId),
				locationId: parseInt(state.form.location),
				amount:
					state.UISettings.isAdvanceFromVehicleType &&
					state.form.selectedVehicle.Deposit > 0
						? state.form.selectedVehicle.Deposit
						: state.paymentSettings.advancePaymentAmount > 0
						? state.paymentSettings.advancePaymentAmount
						: state.summery.total,
			};

			const submitReq = await mainAxios.post("/api/v3/Payment/Link", payload);
			dispatch(changeForm("externalPaymentUrl", submitReq.data.url));
			dispatch(
				changeForm(
					"externalPaymentReferenceNumber",
					submitReq.data.paymentReferenceId
				)
			);

			return submitReq.data;
		} catch (err) {
			console.log(err);
			alert("Error occured while making payment.");

			return false;
		}
	};
};

const createCustomer = () => {
	return async (dispatch) => {
		const state = store.getState();

		let payload = {
			ClientId: parseInt(state.UISettings.clientId),
			FirstName: state.form.firstName,
			LastName: state.form.lastName,
			Company: null,
			HomePhone: state.form.phoneNumber,
			Email: state.form.email,
			customerPassword: generatePassword(),
			Active: true,
			LocationId: parseInt(state.form.location),
			CustomerType: "Online",
		};

		const submitReq = await mainAxios.post("/api/v3/Customers", payload);
		dispatch(changeForm("customerId", submitReq.data));
	};
};

const updatePayment = () => {
	return async (dispatch) => {
		try {
			const state = store.getState();

			let payload = {};
			payload.Email = state.form.email;
			payload.Phone = state.form.phoneNumber;
			payload["VehModifyRQCore"] = {};

			payload["UniqueID"] = { ID: state.form.nextResNumber, Type: "14" };

			if (
				state.UISettings.paymentInfo === "True" &&
				state.form.RawProcessorResponse
			) {
				payload.VehModifyRQCore = {
					RentalPaymentPref: {
						PaymentType: 5,
						GuaranteeTypeCode: 8,
						PaymentCard: {
							CardType: "",
							CardCode: state.form.CardCode,
							CardNumber: state.form.CardNumber,
							ExpireDate: state.form.ExpireDate,
							SeriesCode: state.form.SeriesCode,
						},
						PaymentAmount: {
							Amount: state.summery.payNowTotal.toFixed(2),
							CurrencyCode: state.form.CurrencyCode,
							ApprovalCode: state.form.ApprovalCode,
						},
						Token: state.form.Token,
						CCTransactionType: state.form.CCTransactionType,
						RawProcessorResponse: state.form.RawProcessorResponse,
						MerchantReferenceString: state.form.MerchantReferenceString,
					},
				};
			}

			const submitReq = await mainAxios.post(
				`/api/Rentworks/${state.UISettings.clientId}/UpdatePayment`,
				payload
			);

			if (submitReq.data.hasOwnProperty("vehResRSCore")) {
				dispatch(
					changeForm(
						"reservationNumber",
						submitReq.data.vehResRSCore.vehReservation.vehSegmentCore[0].id
					)
				);
				dispatch(
					changeForm(
						"reservationId",
						submitReq.data.vehResRSCore.vehReservation.vehSegmentCore[0].type
					)
				);
			}

			return submitReq.data;
		} catch (err) {
			console.log(err);
			alert("Error Occured while booking.");

			return false;
		}
	};
};

const cancelReservation = () => {
	return async (dispatch) => {
		try {
			const state = store.getState();

			let payload = {};
			payload["VehCancelRQCore"] = {
				CancelType: "Confirm",
				UniqueID: { ID: state.form.nextResNumber, Type: "14" },
			};

			payload["VehCancelRQInfo"] = {
				RentalInfo: {
					PickUpLocation: {
						LocationCode: state.form.location,
					},
					ReturnLocation: {
						LocationCode: state.form.differentDropOff
							? state.form.differentDropOffLocation
							: state.form.location,
					},
				},
			};

			await mainAxios.post(
				`/api/Rentworks/${state.UISettings.clientId}/CancelReservation`,
				payload
			);
		} catch (err) {
			console.log(err);
			alert("Error Occured while cancelling.");

			return false;
		}
	};
};

const bookReservation = () => {
	return async (dispatch) => {
		try {
			const state = store.getState();

			let states,
				countryName,
				stateName,
				countries = undefined;
			if (state.form.country) {
				countries = await mainAxios.get(
					`${process.env.REACT_APP_API_BASE_URL}api/v3/Locations/Countries`
				);
				states = await mainAxios.get(
					`${process.env.REACT_APP_API_BASE_URL}api/v3/Locations/Countries/${state.form.country}/States`
				);
				countryName = countries.data.find(
					(m) => m.countryID === parseInt(state.form.country)
				).countryName;
				stateName = state.form.state
					? states.data.find((m) => m.stateID === parseInt(state.form.state))
							.stateCode
					: "";
			}

			let payload = {};
			payload["VehResRQCore"] = {
				Status: "All",
			};
			payload.VehResRQCore["VehRentalCore"] = {
				PickUpDateTime:
					format(state.form.pickUpDate, "yyyy-MM-dd").toString() +
					"T" +
					format(state.form.pickUpTime, "HH:mm").toString(),
				ReturnDateTime:
					format(state.form.returnDate, "yyyy-MM-dd").toString() +
					"T" +
					format(state.form.returnTime, "HH:mm").toString(),
				PickUpLocation: {
					LocationCode: state.form.location,
				},
				ReturnLocation: {
					LocationCode: state.form.differentDropOff
						? state.form.differentDropOffLocation
						: state.form.location,
				},
			};
			payload["ResponseFilter"] = {
				LocationInfoIndicator: "True",
				LocationAddressPhoneIndicator: "True",
				VehiclesIndicator: "True",
				LocationCCInfoIndicator: "True",
				CCSaleOrAuth: "Sale",
				LocationCCInfoReqestedAmt: state.summery.payNowTotal.toFixed(2),
				NextResNumIndicator: "True",
			};
			if (state.form.nextResNumber) {
				payload["ConfID"] = { ID: state.form.nextResNumber, Type: 14 };
			}

			if (state.form.promoCode) {
				payload.VehResRQCore["RateQualifier"] = {
					VendorRateID: state.form.selectedVehicle.vendorRateID,
					PromoDesc: state.form.promoCode,
				};
			} else {
				payload.VehResRQCore["RateQualifier"] = {
					VendorRateID: state.form.selectedVehicle.vendorRateID,
				};
			}

			payload.VehResRQCore["TPA_Extension"] = {};

			payload.VehResRQCore["Customer"] = {};
			payload.VehResRQCore.Customer["Primary"] = {
				Email: {
					EmailType: 2,
					Value: state.form.email,
				},
				CustomerID: [
					{
						Type: 1,
						ID: state.form.customerId ?? "0",
					},
				],
				PersonName: {
					GivenName: state.form.firstName,
					Surname: state.form.lastName,
				},
				Telephone: [
					{
						PhoneTechType: 1,
						PhoneNumber: state.form.phoneNumber,
					},
					{
						PhoneTechType: 5,
						PhoneNumber: state.form.phoneNumber,
					},
				],
				Address: {
					Type: 2,
					StreetNmbr: state.form.address,
					CityName: state.form.city,
					PostalCode: state.form.zipCode,
					StateProv: stateName,
					CountryName: countryName,
				},
			};

			if (state.form.companyName) {
				payload.VehResRQCore.Customer.Primary.CustomerID.push({
					Type: 4,
					ID: state.form.companyName,
				});
			}

			if (state.UISettings.additionalDriver) {
				if (
					state.form.additionalDriversFName &&
					state.form.additionalDriversLName
				) {
					payload.VehResRQCore.Customer["Additional"] = {
						Email: {
							EmailType: 2,
							Value: state.form.additionalDriversEmail,
						},

						CustomerID: [
							{
								Type: 1,
							},
						],
						PersonName: {
							GivenName: state.form.additionalDriversFName,
							Surname: state.form.additionalDriversLName,
						},
						Telephone: [
							{
								PhoneTechType: 1,
								PhoneNumber: state.form.additionalDriversNumber,
							},
							{
								PhoneTechType: 5,
								PhoneNumber: state.form.additionalDriversNumber,
							},
						],
						Address: {
							Type: 2,
						},
					};

					if (state.form.additionalDriverDateOfBirth) {
						payload.VehResRQCore.Customer["Additional"].BirthDate = format(
							state.form.additionalDriverDateOfBirth,
							"yyyy-MM-dd"
						).toString();
					}
				}
			}

			if (
				state.form.additionalDriverLicIssueDate ||
				state.form.additionalDriverLicExp ||
				state.form.additionalDriverLicNumber
			) {
				payload.VehResRQCore.Customer.Additional["Document"] = {
					DocType: 4,
					ExpireDate: state.form.additionalDriverLicExp
						? format(state.form.additionalDriverLicExp, "yyyy-MM-dd").toString()
						: null,
					DocID: state.form.additionalDriverLicNumber,
				};
			}

			if (state.UISettings.showDOB === true) {
				payload.VehResRQCore.Customer.Primary.BirthDate = state.form.dateOfBirth
					? format(state.form.dateOfBirth, "yyyy-MM-dd").toString()
					: "";
			}
			payload.VehResRQCore["VehPref"] = {
				VehClass: {
					Size: state.form.selectedVehicle.vehicleTypeId,
				},
			};

			payload.VehResRQCore["VehicleCharges"] = [];

			state.form.selectedMisc
				.filter((m) => m.isOptional)
				.forEach(function (item) {
					if (item.calculationType === "PerDay" && item.isQuantity) {
						for (let i = 0; i < (item.Quantity || 1); i++) {
							payload.VehResRQCore["VehicleCharges"].push({
								ChargeID: parseInt(item.id),
								Description: item.description,
								Quantity: 1,
								QuantitySpecified: true,
							});
						}
					} else {
						if (item.isQuantity) {
							payload.VehResRQCore["VehicleCharges"].push({
								ChargeID: parseInt(item.id),
								Description: item.description,
								Quantity: item.Quantity ? parseInt(item.Quantity) : 1,
								QuantitySpecified: true,
							});
						} else {
							payload.VehResRQCore["VehicleCharges"].push({
								ChargeID: parseInt(item.id),
								Description: item.description,
							});
						}
					}
				});

			payload.VehResRQCore["RateDistance"] =
				state.form.selectedVehicle.rates.rateDistance;

			//payload.VehResRQCore.VehicleCharges.push(state.form.selectedVehicle.rates.vehicleCharges.vehicleCharge);

			if (state.UISettings.driverLicense === "True") {
				payload.VehResRQCore.Customer.Primary["Document"] = {
					DocType: 4,
					ExpireDate: format(state.form.driverLicExp, "yyyy-MM-dd").toString(),
					DocID: state.form.driverLicNumber,
				};
			}

			if (
				state.UISettings.paymentInfo === "True" &&
				state.form.RawProcessorResponse
			) {
				payload["VehResRQInfo"] = {
					RentalPaymentPref: [
						{
							GuaranteeTypeCode: 8,
							PaymentCard: {
								CardType: 1,
								CardCode: state.form.CardCode,
								CardNumber: state.form.CardNumber,
								ExpireDate: state.form.ExpireDate,
								SeriesCode: state.form.SeriesCode,
							},
							PaymentAmount: {
								Amount: state.summery.total,
								CurrencyCode: state.form.CurrencyCode,
								ApprovalCode: state.form.ApprovalCode,
							},
							Token: state.form.Token,
							CCTransactionType: state.form.CCTransactionType,
							RawProcessorResponse: state.form.RawProcessorResponse,
						},
					],
				};
			}
			if (state.promotionDiscount) {
				payload.PromotionsAndCorpResponse = state.promotionDiscount;
			}
			if (
				state.form.insuranceCompany ||
				state.form.policyNumber ||
				state.form.InsuranceExpiryDate
			) {
				payload.VehResRQCore.TPA_Extension["Insurance"] = {
					InsCompany: state.form.insuranceCompanyName,
					PolicyNumber: state.form.policyNumber,
					ExpiryDate: state.form.insuranceExpiryDate
						? format(state.form.insuranceExpiryDate, "yyyy-MM-dd").toString()
						: null,
				};
			}

			let submitReq = {};

			if (state.form.payNow) {
				submitReq = await mainAxios.post(
					`/api/Rentworks/${state.UISettings.clientId}/ReserveVehiclePayNow`,
					payload
				);
			} else {
				submitReq = await mainAxios.post(
					`/api/Rentworks/${state.UISettings.clientId}/ReserveVehicle`,
					payload
				);
			}

			dispatch(changeForm("ccMerchantInfo", submitReq.data.ccMerchantInfo));
			dispatch(
				changeForm(
					"reservationNumber",
					submitReq.data.vehResRSCore.vehReservation.vehSegmentCore[0].id
				)
			);
			dispatch(
				changeForm(
					"reservationId",
					submitReq.data.vehResRSCore.vehReservation.vehSegmentCore[0].type
				)
			);

			return submitReq.data;
		} catch (err) {
			console.log(err);
			alert("Error Occured while booking.");

			return false;
		}
	};
};

const makePayment = () => {
	return async (dispatch) => {
		try {
			const state = store.getState();

			const payload = {
				ClientId: parseInt(state.UISettings.clientId),
				CreditCardType: state.form.creditCardNumber.replaceAll(" ", "")
					? creditCardType(state.form.creditCardNumber.replaceAll(" ", ""))[0]
							.type
					: "VISA",
				NameOnCard: state.form.cardHolderName,
				CreditCardNo: state.form.creditCardNumber.replaceAll(" ", ""),
				CreditCardExpiryYear: parseInt(
					"20" + state.form.creditCardExpire.split("/")[1]
				),
				CreditCardExpiryMonth: parseInt(
					state.form.creditCardExpire.split("/")[0]
				),
				CreditCardCVSNo: state.form.cvv,
				FirstName: state.form.firstName,
				LastName: state.form.lastName,
				Email: state.form.email,
				BillingAddress1: state.form.address,
				BillingCity: state.form.city,
				BillingZipCode: state.form.zipCode,
				BillingStateId: parseInt(state.form.state),
				BillingCountryId: parseInt(state.form.country),
				SubTotal: state.summery.subTotal,
				TotalTax: state.summery.totalTax,
				TransactionType: state.paymentSettings.transactionType,
				AdvancePaymentAmount:
					state.UISettings.isAdvanceFromVehicleType &&
					state.form.selectedVehicle.Deposit > 0
						? state.form.selectedVehicle.Deposit
						: state.paymentSettings.advancePaymentAmount,
				ReservationId: state.form.reservationId,
				ExternalPaymentReferenceNumber:
					state.form.externalPaymentReferenceNumber,
			};

			const submitReq = await mainAxios.post("/api/v3/Payment", payload);

			return submitReq.data;
		} catch (err) {
			console.log(err);
			alert("Error Occured while booking.");

			return false;
		}
	};
};

const fetchTerms = (clientId) => {
	return async (dispatch) => {
		try {
			const lang = new URLSearchParams(window.location.search).get("lang");

			clientId = clientId || store.getState().UISettings.clientId;

			const url = `${process.env.REACT_APP_API_BASE_URL}api/v3/Clients/${clientId}/Terms`;

			// if(lang){
			//     url += '&Language=' + lang;
			//  }

			const termsReq = await mainAxios.get(url);

			dispatch({ type: ActionsType.SET_TERMS, data: { terms: termsReq.data } });
		} catch (err) {
			console.log(err);
			alert("Error Occured while fetching the terms.");
		}
	};
};

const verifyPromotion = (
	clientId,
	promotionCode,
	startingDate,
	endingDate,
	locationId
) => {
	return async (dispatch) => {
		try {
			const state = store.getState();

			let pickUpDate = state.form.pickUpDate;
			let returnDate = state.form.returnDate;
			let rateQualifier = state.form.CorpDiscoundNumber;
			let promotionDiscount = state.summery.promotionDiscount;
			if (rateQualifier) {
				let {
					returnTime,
					pickUpTime,
					location,
					differentDropOff,
					differentDropOffLocation,
				} = state.form;

				const checkOut =
					format(pickUpDate, "yyyy-MM-dd").toString() +
					"T" +
					format(pickUpTime, "HH:mm").toString();
				const checkIn =
					format(returnDate, "yyyy-MM-dd").toString() +
					"T" +
					format(returnTime, "HH:mm").toString();

				var prefs = state.UISettings.vehicleTypes.map((item) => ({
					Size: item.id,
				}));

				const payload = {
					VehAvailRQCore: {
						Status: "Available",
						VehRentalCore: {
							PickUpDateTime: checkOut,
							ReturnDateTime: checkIn,
							PickUpLocation: {
								LocationCode: location,
							},
							ReturnLocation: {
								LocationCode: differentDropOff
									? differentDropOffLocation
									: location,
							},
						},
						RateQualifier: {
							CorpDiscountNmbr: rateQualifier,
							PromoDesc: promotionDiscount,
						},
						VehPrefs: {
							VehPref: prefs,
						},
					},
				};

				let url = `/api/Rentworks/${state.UISettings.clientId}/GetVehAvailRate`;

				const vehicleReq = await mainAxios.post(url, payload);
				console.log();
				if (vehicleReq.data.vehicleTypes.length === 0) {
					//alert("Invalid Corporate Code");
					//dispatch(alert("error", "Invalid Corporate Code", 3000));
					throw ErrorTypes.INVALID_PROMOCODE;
					return false;
				}
			}
		} catch (err) {
			if (err === ErrorTypes.INVALID_PROMOCODE) {
				throw ErrorTypes.INVALID_PROMOCODE;
			}
			console.log(err);
		}
	};
};

const GetPromotionData = async (promotionModelParameter) => {
	let url = `/api/Promotions/GetPromotionData`;
	const response = await mainAxios.post(url, promotionModelParameter);

	return new Promise((resolve, reject) => {
		xml2js.parseString(
			response.data,
			{ explicitArray: false },
			(err, result) => {
				if (err) {
					console.error(err);
					reject(err);
				} else {
					const pcMessage =
						result["SOAP-ENV:Envelope"]["SOAP-ENV:Body"][
							"otavehavailrateResponse"
						]["pcMessage"];
					xml2js.parseString(
						pcMessage,
						{ explicitArray: false, ignoreNamespaces: true },
						(err, innerResult) => {
							if (err) {
								console.error(err);
								reject(err);
							} else {
								if (
									innerResult["OTA_VehAvailRateRS"] &&
									innerResult["OTA_VehAvailRateRS"]["VehAvailRSCore"] &&
									innerResult["OTA_VehAvailRateRS"]["VehAvailRSCore"][
										"VehVendorAvails"
									] &&
									innerResult["OTA_VehAvailRateRS"]["VehAvailRSCore"][
										"VehVendorAvails"
									]["VehVendorAvail"] &&
									innerResult["OTA_VehAvailRateRS"]["VehAvailRSCore"][
										"VehVendorAvails"
									]["VehVendorAvail"]["VehAvails"] &&
									innerResult["OTA_VehAvailRateRS"]["VehAvailRSCore"][
										"VehVendorAvails"
									]["VehVendorAvail"]["VehAvails"]["VehAvail"] &&
									innerResult["OTA_VehAvailRateRS"]["VehAvailRSCore"][
										"VehVendorAvails"
									]["VehVendorAvail"]["VehAvails"]["VehAvail"]["VehAvailCore"]
								) {
									const totalCharge =
										innerResult["OTA_VehAvailRateRS"]["VehAvailRSCore"][
											"VehVendorAvails"
										]["VehVendorAvail"]["VehAvails"]["VehAvail"][
											"VehAvailCore"
										]["TotalCharge"];
									const promotion =
										innerResult["OTA_VehAvailRateRS"]["VehAvailRSCore"][
											"VehVendorAvails"
										]["VehVendorAvail"]["VehAvails"]["VehAvail"][
											"VehAvailCore"
										]["Promotion"];
									const promotionDiscount = {
										RateTotalAmount: totalCharge.$.RateTotalAmount,
										EstimatedTotalAmount: totalCharge.$.EstimatedTotalAmount,
										RateTotalAmountWithPromo:
											totalCharge.$.RateTotalAmountWithPromo,
										EstimatedTotalAmountWithPromo:
											totalCharge.$.EstimatedTotalAmountWithPromo,
										PromotionDescription: promotion.$.Description,
										Value: promotion.$.Value,
										Error: promotion.$.Error,
										Code: promotion.$.Code,
										totalTaxes: 0,
									};

									resolve(promotionDiscount);
								}
							}
						}
					);
				}
			}
		);
	});
};

export {
	fetchClient,
	fetchStoreHours,
	fetchVehicleType,
	fetchVehicles,
	fetchMiscCharges,
	fetchInventoryCharges,
	fetchTaxes,
	getSummery,
	createCustomer,
	getCustomer,
	bookReservation,
	cancelReservation,
	updatePayment,
	getPaymentLink,
	getQuote,
	makePayment,
	fetchTerms,
	verifyPromotion,
};
